<template>
  <div style="width:100%;">
    <iframe src="/static/privacy-agreement.html" scrolling="0" style="width:100%;min-height:100vh;height:auto;" frameborder="0"></iframe>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
